import { Routes, Route } from "react-router-dom";
import { NotFoundPage } from "./pages/not-found-page";
import { DashboardPage } from "./pages/dashboard-page";
import { Layout } from "./Layout";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default App;
