import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAPI } from "../api";

export function EnterSiteKey({
  onClose,
  message,
}: {
  onClose: (siteKey?: string) => void;
  message?: string;
}) {
  const api = useAPI();
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <div className="no-site-key">
        <div className="message">
          {message ||
            "No site key entered yet. To receive the site key please contact us with a request at info@integracije.si."}
        </div>
        <div>
          <Button onClick={handleShow}>Enter site key</Button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter site key</Modal.Title>
        </Modal.Header>
        <Form
          noValidate
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setError(null);

            const siteKey = event.currentTarget.elements.namedItem(
              "site_key"
            ) as HTMLInputElement;

            if (!siteKey.value?.length) {
              setError("Site key is required");
            } else {
              const { data } = await api.post("information", {
                site_key: siteKey.value,
              });

              onClose(data.site_key);
              handleClose();
            }
          }}
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Site key</Form.Label>
              <Form.Control
                type="text"
                placeholder="Site key"
                name="site_key"
              />
              {error ? <div className="form-control-error">{error}</div> : null}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" type="button" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
}
