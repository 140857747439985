import React, { useEffect, useState } from "react";
import { useAPI } from "../api";
import { SiteInformation } from "../types";

import { Loader } from "../components/loader";
import { EnterSiteKey } from "../components/enter-site-key";
import { IntegrationList } from "../components/integration-list";

export function DashboardPage() {
  const api = useAPI();
  const [information, setInformation] = useState<SiteInformation | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get<SiteInformation>("information");
        setInformation(data);
      } catch {
        //
      }
    })();
  }, [api]);

  return (
    <div className="container">
      <div style={{ margin: "20px 0" }}>
        <h1>Integrations</h1>
        <h3>View and monitor your integrations.</h3>
      </div>
      <div>
        {!information ? (
          <Loader />
        ) : information.site_key ? (
          <IntegrationList />
        ) : (
          <EnterSiteKey
            onClose={(siteKey) => {
              if (siteKey) {
                setInformation({
                  ...information,
                  site_key: siteKey,
                });
              }
            }}
          />
        )}
      </div>
    </div>
  );
}
