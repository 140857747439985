import { Integration } from "../types";
import { useAPI } from "../api";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { EnterSiteKey } from "./enter-site-key";

function Status({ status }: { status: Integration["status"] }) {
  switch (status) {
    case "INACTIVE":
      return (
        <Badge bg="danger" text="dark">
          Inactive
        </Badge>
      );
    case "ACTIVE":
      return (
        <Badge bg="success" text="dark">
          Active
        </Badge>
      );
  }
}

export function IntegrationList() {
  const api = useAPI();
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [invalidSiteKey, setInvalidSiteKey] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get<Integration[] | null>("integrations");
        if (Array.isArray(data)) {
          setIntegrations(data);
        } else {
          // invalid site key
          setInvalidSiteKey(true);
        }
      } catch {
        //
      }
    })();
  }, [api, invalidSiteKey]);

  return invalidSiteKey ? (
    <EnterSiteKey
      onClose={() => {
        // reload
        setInvalidSiteKey(false);
      }}
      message="Entered site key is invalid. Please enter valid site key."
    />
  ) : (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Status</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        {integrations.map((integration) => (
          <tr key={integration.id}>
            <td>{integration.name}</td>
            <td style={{ textAlign: "center" }}>
              <Status status={integration.status} />
            </td>
            <td>{integration.log_details}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
